.custom-footer {
  height: auto;
  background-color: $primary;
  padding: 2rem 6%;
  text-align: left;
}

a + a {
  margin-left: 12px;
}

.footer-left {
  color: #000000;
  background-image: url("../images/illustration_contorhaus_bubble.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 20rem;
}

.footer-left h3 {
  color: #000000;
}

.footer-left a {
  box-shadow: inset 0 -3px 0 0 #000000;
  padding: 0 1px 1.1%;
  color: #000000;
}

.footer-left a:hover {
  box-shadow: inset 0 -20px 0 0 #000000;
  color: #ffffff;
}

.logo-footer {
  width: 220px;
}

.legal-info {
  background-color: #ffffff;
  font-size: 0.9rem;
}

.legal-info a {
  box-shadow: inset 0 -9px 0 0 #EDA115;
  padding: 0 1px 0.2%;
  color: #000000;
}

.legal-info a:hover {
  box-shadow: inset 0 -20px 0 0 #EDA115;
}

