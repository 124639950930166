.note {
  font-size: 0.8rem;
  font-weight: 400;
}

.box-title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;

  &.footer-title {
    font-size: 20px;
  }
}

.label-info {
  font-size: 20px;
}

.hero-title {
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: 700;

  @media (max-width: 420px) {
    font-size: 2rem
  }
}

.article-text a {
  text-decoration: none;
  color: $primary;
}

.article-text a:hover {
  text-decoration: underline;
}

a[href^="tel:"], a[href^="mailto:"], a[href^="url:"] {
  img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
}

.page-header h2 {
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
}

.article-page h1 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0.2rem;
  overflow-wrap: break-word;
}

.article-page label {
  font-size: 1.2rem;
  font-weight: 400;
  color: $primary;
  margin-top: 0.2rem;
}

.article-text {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px 5%;
}
