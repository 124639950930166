.home-cat {
  text-align: center;
  margin-top: 0.1rem;

  img {
    width: 120px;
    margin-bottom: 0.8rem;

    @media (max-width: 768px) {
      width: 80px;
      margin-bottom: 0.5rem;
    }
  }

  label {
    font-weight: 500;
    color: $primary;
    line-height: 1.5rem;
    font-size: 24px;
  }

  ul {
    display: inline;
    list-style-type: none;
    width: 100%;
    height: 525px;
    padding-left: 0;
  }

  li {
    margin-right: 1%;
    padding-right: 0;
    display: inline-flex;
    width: auto;
  }

  li div {
    background-color: none;
    min-width: 10rem;
    text-align: center;
    margin: 0;
    max-height: 90%;
    padding: 0.2rem 0.4rem;
  }
}

.map-container{

  iframe {
    pointer-events: none;
  }

  &.is-focused {
    iframe {
      pointer-events: auto;
    }
  }
}

.home-news {
  color: #000000;
  min-height: 30rem;
  overflow: visible;
  max-width: 53rem;
  padding: 2rem 0 0;

  ul {
    list-style-type: none;
  }
}

.color-section-green-ltr {
  background-image: url("/assets/images/bg_ltrGruen.png");
  color: #ffffff;
}

.image-grid {
  img {
    height: 170px;
    margin: 8px 6px;
  }
}

.image-fill img {
  width: 100%;
  height: auto;
}

.color-selection-wrapper {
  &:before:before {
    content: '';
    background: #008999;
    position: absolute;
    width: 100%;
    height: 10px;
    transform: rotate(2deg);
  }
}

.color-section-green-ltr-startpage {
  position: relative;
  background: #008999;
  padding-top: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;

  &:before {
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(2deg, #008999, #008999 50%, #fff calc(50% + 1px),#ffffff 100%);
  }



  &:after {
    content: "";
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(178deg,#008999, #008999 50%, #fff calc(50% + 1px), #ffffff 100%);
  }

  @media (max-width: 420px) {
    margin-top: 4rem;
  }
}

.color-section-yellow-straight-rtl {
  position: relative;
  background: #eea114;
  margin-top: 3rem;

  &:before {
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(176deg, #ffffff, #ffffff 50%, #eea114 calc(50% + 1px), #eea114 100%);
  }
}
