form label {
  line-height: 0.6rem;
  padding-top: 1.2rem;
  font-weight: 400;
}

form input[type="submit"] {
  border: none;
  color: #FFFFFF;
  background-color: $primary;
  height: 48px;
  padding: 0 1.2rem;
  margin-top: 1.2rem;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

form label {
  line-height: 0.6rem;
  padding: 1.2rem 0 1rem;
  font-weight: 400;
  font-size: 15px;
}

form input[type="submit"]:hover {
  background-color: rgba(13,58,64,1);
}

.form-check-input {
  padding: 0;
  margin-right: 0.4rem;
  min-height: unset;
}

input[type="submit" i]:disabled {
  opacity: 0.6;
  pointer-events: none;
}


.form-section-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.2rem;
  margin-bottom: 6px;
}

.success-message {
  background: #008999;
  color: white;
  padding: 12px;
  margin: 24px 0;
  border-radius: 0;
  text-align: center;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-group {
  display: flex;
  margin: 0 -12px;

  & > * {
    width: 50%;
    margin: 12px;
  }
}

input,
select,
textarea {
  min-height: 42px;
  width: 100%;
  padding: 0 1.2rem;
  font-size: 1rem;
  color: #000000;
  background-color: #e5eced;
  transition: border-color ease-in-out 0.15s;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  border: none;
}
