.reset-element {
  transform: translateY(-100px);
}

.filter-container {
  z-index: 11;
  top: 78px;
  background-color: #fff;
  padding: 16px 0;
  width: 100%;

  .btn {
    background-color: #E5ECED;
    border-radius: 50px;
    padding: 8px 20px;
    margin: 0 4px;
    border: 2px #008899 solid;
  }

  .btn.active {
    color: #fff;
    background-color: $primary;
    outline: none;
  }
}

.filterDiv {
  display: none;
}

.show {
  display: block;
}


.filter-items {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 15px 0px;

  &:first-child {
    margin-bottom: 25px;
  }

  @media (max-width: 420px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: scroll;

    gap: 0px 0px;

     .btn {
       margin-bottom: 7px;
     }
  }
}
/* Style the buttons */

