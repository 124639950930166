.hero {
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 121.9%;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-bottom: 15px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    padding-bottom: 15px;
  }
}