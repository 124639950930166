.header-cta p {
  font-weight: 700;
  line-height: 1.5;
  padding-right: 37%;
}


.container-header {
  width: 100%;
  top: 0;
  padding-top: 2rem;
  padding-right: 2px;
  padding-left: 2px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  z-index: 10;
  background-color: #ffffff;
  height: 164px;

  img {
    width: 220px;
  }

  @media (max-width: 420px) {
    height: 80px;
    padding-top: 0;

    img {
      width: 140px;
    }
  }
}

.dense {
  .container-header {
    height: 80px;
    padding: 1rem;
    transition: all 0.2s linear;
    overflow: hidden;

    img {
      width: 100px;
      transition: all 0.2s linear;
    }
  }


  .header-cta {
    transform: scale(0.6) translateY(-2rem);
    transform-origin: top right;
  }
}

.header-cta {
  text-transform: uppercase;
  background-image: url("/assets/images/illustration_contorhaus_bubble_reflected.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 9rem;
  width: 9rem;
  text-align: right;
  margin-left: 64%;
  color: #ffffff;

  @media (max-width: 420px) {
    margin-left: 0;
    transform: scale(0.8);
    transform-origin: top right;
  }
}

.header-cta a {
  box-shadow: inset 0 0 0 0 #000000;
  color: #000000;
}

.header-cta a:hover {
  box-shadow: inset 114px -26px 0 0 #000000;
  color: #ffffff;
}

.nav-toggle {
  cursor: pointer;
  height: 2rem;
  left: 2rem;
  position: fixed;
  top: 2rem;
  width: 2.2rem;
  z-index: 101;
  transition: top 0.2s linear;

  @media (max-width: 420px) {
    left: 1rem;
    transform: scale(0.7);
    transform-origin: left center;
    top: 1rem;
  }
}

.dense {
  .nav-toggle {
    top: 1.5rem;
  }
}

.nav-toggle:hover {
  opacity: 0.8;
}

.nav-toggle .nav-toggle-bar,
.nav-toggle .nav-toggle-bar::after,
.nav-toggle .nav-toggle-bar::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #000000;
  content: '';
  height: 0.26rem;
  transition: all 0.5s;
  width: 100%;
}

.nav-toggle .nav-toggle-bar {
  margin-top: 0;
}

.nav-toggle .nav-toggle-bar::after {
  margin-top: 0.8rem;
}

.nav-toggle .nav-toggle-bar::before {
  margin-top: -0.8rem;
}

.nav-toggle.expanded .nav-toggle-bar {
  background: transparent;
}

.nav-toggle.expanded .nav-toggle-bar::after,
.nav-toggle.expanded .nav-toggle-bar::before {
  background: #000000;
  margin-top: 0;
}

.nav-toggle.expanded .nav-toggle-bar::after {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-toggle.expanded .nav-toggle-bar::before {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav {
  background: #e9ecef;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  height: 100vh;
  left: -26rem;
  padding: 6rem 2rem 2rem;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  width: 26rem;
  z-index: 100;
}

.nav.expanded {
  left: 0;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav ul li ul {
  padding-left: 1.4rem;
}

.nav ul li a {
  cursor: pointer;
  font-size: 1.3rem;
  color: #000000;
  text-decoration: none;
}

.nav-main-item {
  position: relative;
}
