// Colors
$primary: #008999;
$secondary: #eea114;
$light: #fff;

// Fonts
$font-family-base: Ubuntu, sans-serif;

@import 'bootstrap-variables';
@import 'bootstrap/bootstrap';

//Base
@import "base/global";
@import "base/form";
@import "base/fonts";
@import "base/typo";
@import "base/overrides";

// Components
@import "components/navigation";
@import "components/footer";
@import "components/button";
@import "components/hero-slider";
@import "components/filter-bar";
@import "components/feature-section";
@import "components/page-header";
@import "components/contorhaus-card";
@import "components/hero";

// Pages
@import "pages/index";
@import "pages/rooms";
@import "pages/privacy-policy";
