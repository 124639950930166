.button-scrollup:hover {
  background-color: #445155;
  text-decoration: none;
  color: #fff;

  img {
    filter: brightness(24);
  }
}

.button-scrollup {
  background: #eda115;
  color: #000000;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  padding: 12px 16px;
  transition: color 0.3s linear, background 0.3s ease-in-out;
  -moz-transition: color 0.3s linear, background 0.3s ease-in-out;
  -webkit-transition: color 0.3s linear, background 0.3s ease-in-out;
  text-align: center;
}

.meta {
  display: flex;
  align-items: baseline;
}

.badge-secondary {
  background: #eda114;
  margin-right: 12px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 15px;
  border: none;
  transition: 0.5s;
}

.openbtn:hover {
  background-color: #000000;
  color: #ffffff;
}

.openbtn:focus {
  outline: none;
  color: #ffffff;
}

.btn {
  border: none;
  outline: none;
  padding: 12px 16px;
  background-color: none;
  cursor: pointer;
  color: $primary;
  opacity: 0.8;
  border-radius: 0;
}

.btn:hover {
  color: rgba(13, 58, 64, 1);
}

button:focus {
  outline: 0 !important;
}

.primary-button {
  box-shadow: inset 0 -10px 0 0 #eda115;
  padding: 0 0.46rem 1%;
  font-size: 1.1rem;
}

.secondary-button {
  box-shadow: inset 0 -10px 0 0 #90c8ce;
  padding: 0 0.46rem 1%;
  font-size: 1.1rem;
}

.primary-button-whiteShadow {
  box-shadow: inset 0 -10px 0 0 #ffffff;
  padding: 0 0.46rem 1%;
  font-size: 1.4rem;
}

.primary-button:hover {
  box-shadow: inset 0 -32px 0 0 #eda115;
  color: #000000;
}

.secondary-button:hover {
  box-shadow: inset 0 -32px 0 0 #90c8ce;
  color: #000000;
}

.primary-button-whiteShadow:hover {
  box-shadow: inset 0 -32px 0 0 #ffffff;
  color: #000000;
}
