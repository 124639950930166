.contorhaus-card {
  background-color: #E5ECED;

  img {
    height: 75px;
    width: auto;
    padding-bottom: 15px;
  }

  label {
    color: #008999;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 15px;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #0D3A40;
    padding-bottom: 15px;
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 14px;
    color: #0D3A40;
  }

  .primary-button, .secondary-button {
    font-size: 1rem;
    line-height: 14px;
    font-weight: 400;
    color: #0D3A40;
  }

  .primary-button {
    box-shadow: inset 0 -6px 0 0 #FFA61A;
  }

  .primary-button:hover {
    box-shadow: inset 0 -32px 0 0 #FFA61A;
    color: #000000;
  }

  .secondary-button {
    box-shadow: inset 0 -6px 0 0 #90C8CE;
  }

  .secondary-button:hover {
    box-shadow: inset 0 -32px 0 0 #90C8CE;
    color: #000000;
  }
}