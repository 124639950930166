.cc-color-override--1229562179.cc-window {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.cc-btn {
  text-decoration: none;
  border-radius: 6px;
}

.fc-event-title-container, .fc-event-title {
  display: none;
}
