
body {
  line-height: 1.8;
  padding: 164px 0 0;
  -webkit-font-smoothing: antialiased;
}

body a {
  transition: all 0.3s ease-in-out;
}

body a:hover {
  text-decoration: none;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

a:-webkit-any-link {
  color: rgba(13,58,64,1);
  cursor: pointer;
  text-decoration: none;
  transition: all ease 0.3s;
}

.text-white a {
  color: #FFFFFF;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.2rem 0;
}

h3 {
  text-transform: uppercase;
}

p {
  font-weight: 400;
  line-height: 1.9;
}

.empty-page {
  height: 260px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #008999;

  .primary-button {
    margin-top: 16px;
  }
}

.content-page {
  padding: 48px 0;

  @media (max-width: 420px) {
    padding: 48px 24px;
  }

  img {
    margin: 16px 0;
    width: 90%;
    max-width: 600px;
  }
}

.color-section {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 2rem;
}

@media (max-width: 768px) {
  .color-section {
    padding: 0.2rem;
  }
}


.date-label {
  font-weight: 500;
  color: #008999;
  line-height: 1.5rem;
  font-size: 1.4rem;
}
