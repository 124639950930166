.room-feature-container {
  text-align: center;
  background-color: #e5eced;

  label {
    font-size: 1rem;
    font-weight: 500;
    color: $primary;
  }

  p {
    font-size: 1.2rem;
    font-weight: 500;
  }

  ul {
    display: inline;
    list-style-type: none;
    padding-left: 0;
    width: 100%;
  }

  li {
    margin-right: 5%;
    padding-right: 0.5%;
    display: inline-flex;
    width: auto;
    min-width: 3.87rem;
  }

  li:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.calendar-container {
  background: #fff;
  padding: 16px;
  border-radius: 4px;
}

.room-preview-image {
  object-fit: cover;
  height: 100%;
  width: 100% !important;
}

.booking-form {
  max-width: 550px;
  background: #fff;
  padding: 24px 36px;
  border-radius: 4px;
}

.optional {
  text-align: center;
  margin-top: 6%;
  margin-bottom: 6%;
}

.optional img {
  width: 80px;
}

.optional label {
  font-weight: 500;
  color: $primary;
  line-height: 1.5rem;
}

.optional ul {
  display: inline;
  list-style-type: none;
  width: 100%;
  height: 100px;
  padding-left: 0;
}

.optional ul li {
  margin-right: 1%;
  padding-right: 0;
  display: inline-flex;
  width: auto;
}

.optional ul li div {
  background-color: #E5ECED;
  text-align: center;
  margin: 0;
  min-width: 15rem;
  max-width: 15rem;
  min-height: 15rem;
}

.color-section-yellow-ltr {
  background-color: #EDA115;
}

.price-tag {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
}
