.page-header {
  margin-bottom: 24px;

  .image-fill img {
    width: 100%;
    height: auto;
  }

  .page-header-content {
    display: flex;
    margin-right: 6rem;
    flex-direction: column;
    align-items: center;

    @media (max-width: 420px) {
      margin-right: 0;
      padding: 0 20px;
    }

    &.profile {
      display: flex;
      align-items: flex-start;

      .profile-header {
        display: flex;

        img {
          border-radius: 6px;
          margin-right: 36px;
          min-width: 100px;
          width: 100px;
        }

        .value-small {
          margin: 0;
          padding: 0 0 12px;
        }

        @media (max-width: 420px) {
          flex-direction: column;
        }
      }

      .profile-contact {
        margin: 16px 0 48px 136px;
        line-break: strict;
        font-size: 18px;

        @media (max-width: 420px) {
         margin-left: 0;
        }
      }
    }
  }

  .label {
    margin-top: 40px;
    font-size: 24px;
  }

  .page-header-icon {
    width: 120px
  }

  .value {
    font-size: 40px;
    padding: 0;
  }

  .value-small {
    font-size: 2rem;

    @media (max-width: 420px) {
      font-size: 1.4rem;
    }
  }
}
