.feature-section {
  text-align: center;
  width: 100%;
  margin-bottom: 64px;

  ul {
    display: inline;
    list-style-type: none;
    width: 100%;
    height: 525px;
    padding-left: 0;
  }

  .location-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 0;
    margin-right: 4px;
  }

  img {
    width: 168px;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      width: 80px;
      margin-bottom: 0.5rem;
    }
  }

    li {
      margin-right: 1%;
      padding-right: 0;
      display: inline-flex;
      width: auto;
    }

    label {
      font-weight: 500;
      color: $primary;
      line-height: 1.5rem;
      font-size: 1.4rem;
    }

    ul li div {
      background-color: #e5eced;
      min-width: 10rem;
      text-align: center;
      margin: 0 0 2rem;
      max-height: 90%;
      padding: 1rem 2.4rem;

      @media (max-width: 768px) {
        padding: 1.6rem 1rem;
      }
    }
}

.feature-item {
  border: 10px solid #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-origin: border-box;
}

.feature-item-footer {
  justify-content: space-between;
  left: 0;
  display: flex;
  padding: 12px;
  bottom: 0;
  margin: 1.6rem 0.3rem -3rem;
}

.feature-item-contentbox {
  padding: 28px 8%;
  margin: 10px 10%;
  border-bottom: 2px solid $primary;
  text-align: left;

  @media (max-width: 420px) {
    padding: 20px 16px;
    margin: 10px 0;
  }
}

.room-row {
  height: 412px;
  overflow: hidden;

  @media (max-width: 420px) {
    height: auto;
    margin: 16px auto;
    width: 95%;
    flex-direction: column-reverse;
  }
}

.room-actions {
  min-height: 62px;
}

.feature-item-label {
  width: 50%;
  background: #e5eced;
  position: relative;

  &.full-width {
    width: 100%;
  }
}

.newsflash, .pos-rel {
  position: relative;
  z-index: 1;
}

.newsflash-item {
  background-color: #e5eced;
  min-width: 10rem;
  text-align: center;
  margin: 0;
  max-height: 90%;
  padding: 3rem 0;

  &.people {

    label {
      font-size: 1rem;
    }

    h1 {
      margin: 0.4rem -1.4rem 0.4rem;
      font-size: 1.2rem;
    }
  }
}

.news-start {
  color: #000000;
  background-image: url('/images/illustration_contorhaus_bubble.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 20rem;
}

.newsflash-image {
  width: 168px;
  margin-bottom: 4rem;
}

.entry-container {
  display: flex;
  align-items: flex-start;

  .entry-content {
    flex: 1;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }

  h2 {
    text-transform: unset;
    margin-right: 36px;

    @media (max-width: 420px) {
      margin-right: 0;
    }
  }
}
