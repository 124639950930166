.slideshow-container {
  max-width: 100vw;
  margin: auto;
  width: 100%;
  position: relative;
}

.mySlides {
  contain: content;
  overflow: hidden;
  height: calc(76vh - 4rem);
  min-height: 450px;
  max-height: 900px;

  @media (max-width: 420px) {
    h2 {
      font-size: 1.2rem;
      padding: 0;
    }
  }
}

.mySlides img {
  width: 100%;
  vertical-align: middle;
  max-width: 100%;
}

button[data-action] {
  display: none;
}

.tns-nav {
  position: absolute;
  bottom: 100px;
  left: calc(50% - 30px);

  @media (max-width: 420px) {
    transform: translateY(-40px);
    margin: 0 auto;
    width: 150px;
    top: unset;
    left: 30%;
    bottom: 110px;
  }

  button {
    height: 20px;
    border: none;
    width: 20px;
    margin: 0 8px;
    border-radius: 100%;
    background: $primary;
    opacity: 0.5;
    display: inline-block;
    transition: opacity 0.6s ease;
    cursor: pointer;

    &.tns-nav-active {
      opacity: 1;
    }
  }
}

.introHeroSlider {
  color: #000000;
  background-image: url('../images/illustration_contorhaus_bubble_reflected.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 30rem;
  overflow: visible;
  position: relative;
  width: 40rem;
  padding: 3rem 5rem 0 10rem;

  @media (max-width: 420px) {
    min-height: 23rem;
    height: auto;
    width: 100%;
    padding: 2rem 1rem 1rem 3rem;
    background-position-x: -40px;
  }
}

.introHeroSlider h3 {
  color: #000000;
  font-size: 2.3em;
  line-height: 1em;
  font-weight: 700;
  padding-bottom: 16px;

  @media (max-width: 420px) {
    font-size: 1.3em;
  }
}

.introHeroSlider p {
  color: #000000;
  font-size: 1rem;
  padding-bottom: 20px;

  @media (max-width: 420px) {
    padding-bottom: 0px;
    line-height: 1.25;
  }
}

.introHeroSlider a {
  text-decoration: none;
  cursor: pointer;
}

.introHeroSlider a:hover {
  color: var(--darkwhite);
  text-decoration: none;
  cursor: pointer;
}
